import React from 'react';
import MyOrgChart from './MyOrgChart'; 

const App = () => {
  return (
    <div>
      <h2>Welcome to the Organization Chart</h2>
      <p>
        Instructions:
        <ul>
          <li>Click on people to get more details about them.</li>
          <li>Click on department names to collapse or expand them.</li>
        </ul>
      </p>
      <MyOrgChart />
    </div>
  );
};

export default App;



