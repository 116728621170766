import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Tree, TreeNode } from 'react-organizational-chart';
import styled from 'styled-components';
import { FaUser, FaBuilding, FaUserTie } from 'react-icons/fa';

const StyledTreeNode = styled(TreeNode)`
  margin: 5px;
  cursor: pointer;
`;

const DepartmentNode = styled.div`
  background-color: #007bff;
  color: white;
  padding: 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
`;

const EmployeeNode = styled.div`
  background-color: #28a745;
  color: white;
  padding: 5px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  margin-left: 20px;
`;

const ManagerNode = styled(EmployeeNode)`
  background-color: #ffcc00;
  border: 2px solid #b38f00;
`;

const DetailPopup = styled.div`
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  padding: 10px;
  box-shadow: 2px 2px 5px rgba(0,0,0,0.2);
  top: ${({ position }) => position.top}px;
  left: ${({ position }) => position.left}px;
`;

const MyOrgChart = () => {
    const [departments, setDepartments] = useState([]);
    const [expandedDepartments, setExpandedDepartments] = useState({});
    const [detailView, setDetailView] = useState({ show: false, data: null, position: { top: 0, left: 0 } });
  
    useEffect(() => {
      const fetchData = async () => {
        const response = await axios.get('https://organization-chart.vp122402.workers.dev/');
        const formattedDepartments = response.data.organization.departments.map(dept => ({
          ...dept,
          manager: dept.employees.find(emp => emp.isManager),
          employees: dept.employees.filter(emp => !emp.isManager)
        }));
        setDepartments(formattedDepartments);
  
        // Initialize all departments as expanded
        const initialExpandedState = {};
        response.data.organization.departments.forEach(dept => {
          initialExpandedState[dept.name] = true;
        });
        setExpandedDepartments(initialExpandedState);
      };
      fetchData();
    }, []);
  
    const toggleDepartment = (deptName) => {
      setExpandedDepartments(prevState => ({
        ...prevState,
        [deptName]: !prevState[deptName]
      }));
    };
  
    const renderEmployeeNode = (employee, isManager = false) => {
      const NodeComponent = isManager ? ManagerNode : EmployeeNode;
      return (
        <StyledTreeNode label={
          <NodeComponent onClick={(e) => showDetails(employee, e)}>
            {isManager ? <FaUserTie style={{ marginRight: '5px' }} /> : <FaUser style={{ marginRight: '5px' }} />}
            {employee.name}
          </NodeComponent>
        } />
      );
    };
  
    const renderTree = (department) => (
      <Tree label={
        <DepartmentNode onClick={() => toggleDepartment(department.name)}>
          <FaBuilding style={{ marginRight: '5px' }} />{department.name}
        </DepartmentNode>
      }>
        {expandedDepartments[department.name] && renderEmployeeNode(department.manager, true)}
        {expandedDepartments[department.name] && department.employees.map(employee => renderEmployeeNode(employee))}
      </Tree>
    );

    const showDetails = (employee, e) => {
        // Check if the same employee is clicked again to toggle the visibility
        if (detailView.show && detailView.data.name === employee.name) {
          setDetailView({ show: false, data: null, position: { top: 0, left: 0 } });
        } else {
          setDetailView({
            show: true,
            data: employee,
            position: { top: e.clientY, left: e.clientX }
          });
        }
      };

  return (
    <div>
      {departments.map(dept => renderTree(dept))}
      {detailView.show && (
        <DetailPopup position={detailView.position}>
          <div><strong>Name:</strong> {detailView.data.name}</div>
          <div><strong>Salary:</strong> ${detailView.data.salary}K</div>
          <div><strong>Office:</strong> {detailView.data.office}</div>
          <div><strong>Skills:</strong> {detailView.data.skills.join(', ')}</div>
        </DetailPopup>
      )}
    </div>
  );
};

export default MyOrgChart;

